"use client";

import { useCurrentCustomer } from "@/context/current-customer-context";
import { util } from "@/lib/utils";
import { useCartStore } from "@/stores/cart-store";
import { Dialog, Transition } from "@headlessui/react";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import LoadingDots from "components/loading-dots";
import { Fragment, useEffect, useRef, useState } from "react";
import { useFormStatus } from "react-dom";
import { useShallow } from "zustand/react/shallow";
import { findOrCreateCartAndSetCookie, redirectToCheckout } from "./actions";
import CartModalTrans from "./cart-modal-trans";
import CloseCart from "./close-cart";
import OpenCart from "./open-cart";

export default function CartModal() {
    const cart = useCartStore(useShallow((state) => state.cart));

    const { customer } = useCurrentCustomer();

    const [isOpen, setIsOpen] = useState(false);
    const quantityRef = useRef(cart?.totalNumUnits);
    const openCart = () => setIsOpen(true);
    const closeCart = () => setIsOpen(false);

    useEffect(() => {
        if (!cart) {
            findOrCreateCartAndSetCookie();
        }
    }, [cart?.cartID]);

    // auto open logic
    // useEffect(() => {
    //     if (
    //         cart?.totalNumUnits &&
    //         cart?.totalNumUnits !== quantityRef.current &&
    //         cart?.totalNumUnits > 0
    //     ) {
    //         if (!isOpen) {
    //             setIsOpen(true);
    //         }
    //         quantityRef.current = cart?.totalNumUnits;
    //     }
    // }, [isOpen, cart?.totalNumUnits, quantityRef]);

    const handleRedirectToCheckout = async () => {
        const result = await redirectToCheckout();
        if (result === "Missing cart ID" || result === "Error fetching cart") {
            console.error(result);
        }
    };

    return (
        <>
            <button aria-label="Open cart" onClick={openCart}>
                <OpenCart quantity={cart?.totalCases} />
            </button>
            <Transition show={isOpen}>
                <Dialog onClose={closeCart} className="relative z-50">
                    <Transition.Child
                        as={Fragment}
                        enter="transition-all ease-in-out duration-300"
                        enterFrom="opacity-0 backdrop-blur-none"
                        enterTo="opacity-100 backdrop-blur-[.5px]"
                        leave="transition-all ease-in-out duration-200"
                        leaveFrom="opacity-100 backdrop-blur-[.5px]"
                        leaveTo="opacity-0 backdrop-blur-none"
                    >
                        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-all ease-in-out duration-300"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition-all ease-in-out duration-200"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                    >
                        <Dialog.Panel className="fixed bottom-0 right-0 top-0 flex h-full w-full flex-col border-l border-neutral-200 bg-white/80 p-6 text-black backdrop-blur-xl dark:border-neutral-700 dark:bg-black/80 dark:text-white md:w-[490px]">
                            <div className="flex items-center justify-between">
                                <p className="text-lg font-semibold">
                                    Cart for {customer?.customerName}
                                </p>
                                <button aria-label="Close cart" onClick={closeCart}>
                                    <CloseCart />
                                </button>
                            </div>

                            {!cart || cart.transactions.length === 0 ? (
                                <div className="mt-20 flex w-full flex-col items-center justify-center overflow-hidden">
                                    <ShoppingCartIcon className="h-16" />
                                    <p className="mt-6 text-center text-2xl font-bold">
                                        Your cart is empty.
                                    </p>
                                </div>
                            ) : (
                                <div className="flex h-full flex-col justify-between overflow-hidden p-1">
                                    <ul className="flex-grow overflow-auto py-4">
                                        {cart.transactions
                                            .sort((a, b) => {
                                                const nameComparison = (
                                                    a.shopProduct.name +
                                                    (a.manualPromotionID ? a.manualPromotionID : "")
                                                ).localeCompare(
                                                    b.shopProduct.name +
                                                        (b.manualPromotionID
                                                            ? b.manualPromotionID
                                                            : "")
                                                );
                                                if (nameComparison !== 0) return nameComparison;

                                                return (
                                                    (util.isNull(a.manualPromotionID)
                                                        ? 0
                                                        : a.manualPromotionID!) -
                                                    (util.isNull(a.manualPromotionID)
                                                        ? 0
                                                        : a.manualPromotionID!)
                                                );
                                            })
                                            .map((cartLine, i) => (
                                                <li
                                                    key={i}
                                                    className="flex w-full flex-col border-b border-neutral-300 dark:border-neutral-700"
                                                >
                                                    <CartModalTrans
                                                        cartLine={cartLine}
                                                        index={
                                                            cartLine.cartTransactionID
                                                                ? cartLine.cartTransactionID
                                                                : 0
                                                        }
                                                        closeCart={closeCart}
                                                    />
                                                </li>
                                            ))}
                                    </ul>

                                    <div className="py-4 text-sm text-neutral-500 dark:text-neutral-400">
                                        <div className="mb-3 flex items-center justify-between border-b border-neutral-200 pb-1 pt-1 dark:border-neutral-700">
                                            <p>Total</p>
                                            <p>{`${new Intl.NumberFormat(undefined, {
                                                style: "currency",
                                                currency: "USD",
                                                currencyDisplay: "narrowSymbol"
                                            }).format(cart.totalExtPrice)}`}</p>
                                        </div>
                                    </div>
                                    <form action={handleRedirectToCheckout}>
                                        <CheckoutButton />
                                    </form>
                                </div>
                            )}
                        </Dialog.Panel>
                    </Transition.Child>
                </Dialog>
            </Transition>
        </>
    );
}
function CheckoutButton() {
    const { pending } = useFormStatus();

    return (
        <button
            className="block w-full rounded-full bg-blue-600 p-3 text-center text-sm font-medium text-white opacity-90 hover:opacity-100"
            type="submit"
            disabled={pending}
        >
            {pending ? <LoadingDots className="bg-white" /> : "Proceed to Checkout"}
        </button>
    );
}
