"use client";

import { CartTransaction } from "@/lib/types/shop-types";
import { useCartStore } from "@/stores/cart-store";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { removeItemInDB } from "components/cart/actions";
import { useFormState } from "react-dom";

export function DeleteItemButton({ cartLine: item }: { cartLine: CartTransaction }) {
    const updateCartItem = useCartStore((state) => state.updateCartItem);
    const [message, formAction] = useFormState(removeItemInDB, null);
    const productID = item.shopProduct.productID;
    const promotionID = item.manualPromotionID;
    const actionWithVariant = formAction.bind(null, {
        cartTransactionID: item.cartTransactionID,
        productID,
        promotionID
    });

    return (
        <form
            action={async () => {
                updateCartItem(productID, "delete", promotionID);
                await actionWithVariant();
            }}
        >
            <button
                type="submit"
                aria-label="Remove cart item"
                className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-neutral-500"
            >
                <XMarkIcon className="mx-[1px] h-4 w-4 text-white dark:text-black" />
            </button>
            <p aria-live="polite" className="sr-only" role="status">
                {message}
            </p>
        </form>
    );
}
