import { Badge } from "@/components/ui/badge";
import { CartTransaction, Pricing } from "@/lib/types/shop-types";
import { BoltSlashIcon } from "@heroicons/react/24/outline";

interface ProductPriceProps {
    price: Pricing | null;
    cartLine?: CartTransaction;
    className?: string;
    [key: string]: any;
}

const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
    }).format(amount);
};

const ProductPrice = ({ price, cartLine, className = "", ...props }: ProductPriceProps) => {
    if (!price) return null;

    const displayPrice = !cartLine ? price.casePrice : cartLine.extPrice;
    const fullPriceDisplay = !cartLine
        ? price.caseFullPrice
        : price.unitFullPrice * cartLine.numUnits;
    const displayDiscount = !cartLine ? price.caseDiscount : cartLine.discount * cartLine.numUnits;
    const hasQuantityDiscount = cartLine && cartLine.quantityPromotionID !== null;

    // console.log(cartLine);

    const hasDiscount = Number(displayDiscount) > 0;

    return (
        <div className={`mb-4 mt-2 flex flex-wrap items-baseline gap-y-1 ${className}`} {...props}>
            {hasQuantityDiscount && (
                <span className="text-sm">
                    <BoltSlashIcon />
                </span>
            )}
            <span className="mr-2 text-xl font-bold">{formatCurrency(displayPrice)}</span>
            {hasDiscount && (
                <div className={`flex min-w-[200px] items-center ${cartLine ? "justify-end" : ""}`}>
                    <span className="text-sm text-gray-500 line-through">
                        {formatCurrency(fullPriceDisplay)}
                    </span>
                    <Badge variant="secondary" className="ml-2">
                        {formatCurrency(displayDiscount)} OFF
                    </Badge>
                </div>
            )}
        </div>
    );
};

export default ProductPrice;
