"use client";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { UserIcon } from "lucide-react";
import { signOut } from "next-auth/react";
import Link from "next/link";

interface UserIconProps {
    imageUrl?: string;
}

export default function UserIconMenu({ imageUrl }: UserIconProps) {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger className="ml-4">
                <Avatar>
                    <AvatarImage src={imageUrl} />
                    <AvatarFallback>
                        <UserIcon className="h-5 w-5" />
                    </AvatarFallback>
                </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                <DropdownMenuItem asChild>
                    <Link href="/account">Account</Link>
                </DropdownMenuItem>
                <DropdownMenuItem asChild>
                    <Link href="/orders">Orders</Link>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => signOut()}>Logout</DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
