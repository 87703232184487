import { CartTransaction } from "@/lib/types/shop-types";
import Image from "next/image";
import Link from "next/link";
import EditQuantityDialog from "../product/edit-quantity";
import ProductPrice from "../product/product-price";
import { DeleteItemButton } from "./delete-item-button";

export default function CartModalTrans({
    cartLine,
    index,
    closeCart
}: {
    cartLine: CartTransaction;
    index: number;
    closeCart: () => void;
}) {
    return (
        <div className="relative flex h-[200px] w-full flex-row justify-between px-1 py-4">
            <div className="absolute z-40 -ml-1 -mt-2">
                <DeleteItemButton cartLine={cartLine} />
            </div>
            <div className="flex flex-row">
                <div className="relative h-16 w-16 overflow-hidden rounded-md border border-neutral-300 bg-neutral-300 dark:border-neutral-700 dark:bg-neutral-900 dark:hover:bg-neutral-800">
                    <Image
                        className="h-full w-full object-cover"
                        width={64}
                        height={64}
                        alt={cartLine.shopProduct.name}
                        src={cartLine.shopProduct.thumbnailImageURL!}
                    />
                </div>
                <Link
                    href={`/${cartLine.shopProduct.state.toLowerCase()}/product/${cartLine.shopProduct.productID}`}
                    onClick={closeCart}
                    className="z-30 ml-2 flex flex-row space-x-4"
                >
                    <div className="flex flex-1 flex-col text-base">
                        <span className="leading-tight">
                            {cartLine.shopProduct.name +
                                (cartLine.manualPromotionID
                                    ? ` (${cartLine.manualPromotionID})`
                                    : "")}
                        </span>
                    </div>
                </Link>
            </div>
            <div className="flex h-16 flex-col justify-between">
                {cartLine.shopProduct.price ? (
                    <ProductPrice
                        className="flex justify-end space-y-2 text-right text-sm"
                        price={{
                            unitPrice: cartLine.unitPrice,
                            casePrice:
                                cartLine.unitPrice * cartLine.shopProduct.wholesaleUnitsPerCase,
                            unitFullPrice: cartLine.shopProduct.price.unitFullPrice,
                            caseFullPrice: cartLine.shopProduct.price.caseFullPrice,
                            unitDiscount: cartLine.discount,
                            caseDiscount:
                                cartLine.discount * cartLine.shopProduct.wholesaleUnitsPerCase,
                            availableQuantityDiscounts: null,
                            promotions: null
                        }}
                        cartLine={cartLine}
                    />
                ) : null}
                <EditQuantityDialog shopProduct={cartLine.shopProduct} showPromotions={false} />
            </div>
        </div>
    );
}
