"use client";

import { Cart } from "@/lib/types/shop-types";
import { useCartStore } from "@/stores/cart-store";
import React from "react";

export function CartInitializer({ cartPromise }: { cartPromise: Promise<Cart | undefined> }) {
    React.useEffect(() => {
        console.log("CartInitializer");

        const initCart = async () => {
            const cart = await cartPromise;
            useCartStore.getState().initialize(cart);
        };
        initCart();
    }, [cartPromise]);

    return null;
}
