import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { ShoppingCart } from "lucide-react";

interface OpenCartProps {
    className?: string;
    quantity?: number;
}

const OpenCart = ({ className, quantity }: OpenCartProps) => {
    return (
        <div className="relative flex h-11 w-11 items-center justify-center rounded-md border border-input bg-background transition-colors hover:bg-accent hover:text-accent-foreground">
            <ShoppingCart
                className={cn("h-4 w-4 transition-all ease-in-out hover:scale-110", className)}
            />

            {quantity ? (
                <Badge
                    variant="default"
                    className="absolute -right-2 -top-2 flex h-4 w-4 items-center justify-center p-0 text-[11px] font-medium"
                >
                    {quantity}
                </Badge>
            ) : null}
        </div>
    );
};

export default OpenCart;
